import React from 'react';

const Profile = () => {
  return (
    <div className="profile-container">
      <h2>Profile</h2>
      <p>Name: John Doe</p>
      <p>Email: john.doe@example.com</p>
    </div>
  );
};

export default Profile;
