import React from 'react';

const NavbarButton = ({
  onClick,
  iconClass = 'fas fa-bars', // Default value for iconClass
  buttonClass = '', // Default value for buttonClass
  ariaLabel = 'Toggle navigation', // Default value for ariaLabel
}) => {
  return (
    <button
      className={`nav-link hover:bg-gray-100 rounded-lg transition-colors duration-200 p-3 ${buttonClass}`} // Added p-3 for padding
      onClick={onClick}
      data-widget="pushmenu"
      role="button"
      aria-label={ariaLabel}
    >
      <i className={iconClass}></i>
    </button>
  );
};

export default NavbarButton;