import React, { useState } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import authService from '../../services/authService';

const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token'); // Extract token from URL

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrors({});

    if (password !== confirmPassword) {
      setErrors({ confirmPassword: 'Passwords do not match' });
      setLoading(false);
      return;
    }

    try {
        const response = await authService.resetUserPassword({ token, password });
      setMessage(response.data.message); // Display success message
    } catch (error) {
      setErrors(error.response?.data?.errors || { general: 'An error occurred' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="hold-transition login-page">
      <div className="login-box">
        <div className="card card-outline card-primary">
          <div className="card-header text-center">
            <h1>
              <b>Reset Password</b>
            </h1>
          </div>
          <div className="card-body">
            <p className="login-box-msg">Enter a new password</p>
            <form onSubmit={handleResetPassword}>
              {/* Password Field */}
              <div className="input-group mb-3">
                <input
                  type="password"
                  className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                  placeholder="New Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-lock"></span> {/* Password Icon */}
                  </div>
                </div>
                {errors.password && <div className="invalid-feedback">{errors.password}</div>}
              </div>

              {/* Confirm Password Field */}
              <div className="input-group mb-3">
                <input
                  type="password"
                  className={`form-control ${errors.confirmPassword ? 'is-invalid' : ''}`}
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-lock"></span> {/* Password Icon */}
                  </div>
                </div>
                {errors.confirmPassword && (
                  <div className="invalid-feedback">{errors.confirmPassword}</div>
                )}
              </div>

              {/* Submit Button */}
              <div className="row">
                <div className="col-12">
                  <button type="submit" className="btn btn-primary btn-block" disabled={loading}>
                    {loading ? 'Resetting...' : 'Reset Password'}
                  </button>
                </div>
              </div>

              {/* Back to Login Link */}
              <div className="row">
                <div className="col-12 text-center mt-3">
                  <p>
                    Remember your password? <a href="/login">Login here</a>
                  </p>
                </div>
              </div>
            </form>

            {/* Success/Error Message */}
            {message && (
              <div className="alert alert-success mt-3" role="alert">
                {message}
              </div>
            )}
            {errors.general && (
              <div className="alert alert-danger mt-3" role="alert">
                {errors.general}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;