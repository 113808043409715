import React, { useState } from 'react';
import axios from 'axios';
import authService from '../../services/authService';

const ForgotPassword = () => {
  const [username, setUsername] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrors({});

    try {
      const response = await authService.forgotUserPassword({ username });
      setMessage(response.data.message); // Display success message
    } catch (error) {
      setErrors(error.response?.data?.errors || { general: 'An error occurred' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="hold-transition login-page">
      <div className="login-box">
        <div className="card card-outline card-primary">
          <div className="card-header text-center">
            <h1>
              <b>Forgot Password</b>
            </h1>
          </div>
          <div className="card-body">
            <p className="login-box-msg">Enter your email to reset your password</p>
            <form onSubmit={handleForgotPassword}>
              {/* Email Field */}
              <div className="input-group mb-3">
                <input
                  type="email"
                  className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                  placeholder="Email"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-envelope"></span> {/* Email Icon */}
                  </div>
                </div>
                {errors.email && <div className="invalid-feedback">{errors.email}</div>}
              </div>

              {/* Submit Button */}
              <div className="row">
                <div className="col-12">
                  <button type="submit" className="btn btn-primary btn-block" disabled={loading}>
                    {loading ? 'Sending...' : 'Send Reset Link'}
                  </button>
                </div>
              </div>

              {/* Back to Login Link */}
              <div className="row">
                <div className="col-12 text-center mt-3">
                  <p>
                    Remember your password? <a href="/login">Login here</a>
                  </p>
                </div>
              </div>
            </form>

            {/* Success/Error Message */}
            {message && (
              <div className="alert alert-success mt-3" role="alert">
                {message}
              </div>
            )}
            {errors.general && (
              <div className="alert alert-danger mt-3" role="alert">
                {errors.general}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;