import React from 'react';

const ProjectsList = ({ projects, onView, onEdit, onDelete }) => {
  return (
    <div className="card">
      <div className="card-body table-responsive p-0">
        <table className="table table-hover text-nowrap">
          <thead>
            <tr>
              <th>Name</th>
              <th>Description</th>
              <th>Category</th>
              <th>Status</th>
              <th>Actions</th> {/* New column for actions */}
            </tr>
          </thead>
          <tbody>
            {projects.map((project) => (
              <tr key={project.id}>
                <td>{project.name}</td>
                <td>{project.description}</td>
                <td>{project.category}</td>
                <td>{project.status}</td>
                <td>
                  {/* View Button */}
                  <button
                    className="btn btn-sm btn-info mr-2"
                    onClick={() => onView(project)}
                    title="View"
                  >
                    <i className="fas fa-eye"></i>
                  </button>
                  {/* Edit Button */}
                  <button
                    className="btn btn-sm btn-primary mr-2"
                    onClick={() => onEdit(project)}
                    title="Edit"
                  >
                    <i className="fas fa-edit"></i>
                  </button>
                  {/* Delete Button */}
                  <button
                    className="btn btn-sm btn-danger"
                    onClick={() => onDelete(project.id)}
                    title="Delete"
                  >
                    <i className="fas fa-trash"></i>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProjectsList;