import { Link, useLocation } from 'react-router-dom';
import React, { useEffect, useRef } from 'react';

const Sidebar = ({ sidebarCollapsed }) => {
  const location = useLocation();
  const [isSidebarVisible, setIsSidebarVisible] = React.useState(false);
  const sidebarRef = useRef(null); // Ref for the sidebar

  const isActive = (path) => location.pathname === path ? 'active' : '';

  // Toggle sidebar visibility
  const toggleSidebar = () => {
    setIsSidebarVisible((prev) => !prev);
  };

  // Close sidebar when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setIsSidebarVisible(false);
      }
    };

    // Add event listener
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <aside
      ref={sidebarRef}
      className={`main-sidebar sidebar-dark-primary elevation-4 ${isSidebarVisible ? 'sidebar-visible' : ''}`}
      style={{ 
        minHeight: '100vh', // Ensure sidebar is at least the height of the viewport
        height: '100%', // Fill the entire height
        position: 'fixed', // Make the sidebar fixed
        top: 0, // Align to the top
        left: 0, // Align to the left
        zIndex: 1000, // Ensure it's above other content
        overflowY: 'auto', // Enable scrolling if content overflows
      }}
    >
      {/* Brand Logo */}
      <Link to="/" className="brand-link">
        <img
          src="/logo192.png"
          alt="app Logo"
          className="brand-image img-circle elevation-3"
          style={{ opacity: 0.8 }}
        />
       <p className="brand-text font-weight-light">Task Note</p>

      </Link>

      {/* Sidebar */}
      <div className="sidebar">
        {/* Sidebar Menu */}
        <nav className="mt-2">
          <ul
            className="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            {/* Dashboard Link */}
            <li className="nav-item">
              <Link to="/dashboard" className={`nav-link ${isActive('/dashboard')}`}>
                <i className="nav-icon fas fa-tachometer-alt"></i>
                <p>Dashboard</p>
              </Link>
            </li>

            {/* Projects Link */}
            <li className="nav-item">
              <Link to="/projects" className={`nav-link ${isActive('/projects')}`}>
                <i className="nav-icon fas fa-project-diagram"></i>
                <p>Projects</p>
              </Link>
            </li>

            {/* Tasks Link */}
            <li className="nav-item">
              <Link to="/tasks" className={`nav-link ${isActive('/tasks')}`}>
                <i className="nav-icon fas fa-tasks"></i>
                <p>Tasks</p>
              </Link>
            </li>

            {/* Subscription Link */}
            <li className="nav-item">
              <Link to="/subscription" className={`nav-link ${isActive('/subscription')}`}>
                <i className="nav-icon fas fa-receipt"></i>
                <p>Subscription</p>
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </aside>
  );
};

export default Sidebar;