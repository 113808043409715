import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from '../Common/Sidebar';
import Footer from '../Common/Footer';
import Header from '../Common/Header';
import subscriptionService from '../../services/subscriptionService';
import NavbarButton from '../Common/NavbarButton';

const Subscription = () => {
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem('isAuthenticated') === 'true'
  );
  const [currentSubscription, setCurrentSubscription] = useState(null);
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [loading, setLoading] = useState(false);

  const toggleSidebar = () => {
    setSidebarCollapsed(!sidebarCollapsed);
  };

  // Fetch current subscription and all plans
  const fetchSubscriptionData = async () => {
    setLoading(true);
    try {
      const response = await subscriptionService.fetchSubscriptionData(); // Updated API call
      const { userSubscription, availablePlans } = response.data;

      setCurrentSubscription(userSubscription);
      setSubscriptionPlans(availablePlans);
    } catch (error) {
      console.error('Failed to fetch subscription data', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSubscriptionData();
  }, []);

  // Handle activating a subscription plan
  const handleActivatePlan = async (planId) => {
    try {
      const response = await subscriptionService.activateSubscription(planId);
      setCurrentSubscription(response.data.data.userSubscription); // Updated to match response structure
      alert('Subscription activated successfully!');
    } catch (error) {
      console.error('Failed to activate subscription', error);
      alert('Failed to activate subscription. Please try again.');
    }
  };

  return (
    <div className={`wrapper ${sidebarCollapsed ? 'sidebar-collapse' : ''}`}>
      {/* Navbar */}
      <nav className="main-header navbar navbar-expand navbar-white navbar-light shadow-sm">
        <NavbarButton
          onClick={toggleSidebar}
          iconClass="fas fa-bars"
          buttonClass="custom-class"
          ariaLabel="Toggle navigation"
        />
        {/* Right Navbar Links */}
        <Header setIsAuthenticated={setIsAuthenticated} />
      </nav>

      <Sidebar sidebarCollapsed={sidebarCollapsed} />

      <div className="content-wrapper bg-gray-50">
        <section className="content py-6">
          <div className="container-fluid">
            {/* Current Subscription Card */}
            <div className="card bg-white rounded-lg shadow-sm mb-6">
              <div className="card-header bg-primary text-white">
                <h3 className="card-title">Current Subscription</h3>
              </div>
              <div className="card-body">
                {loading ? (
                  <p>Loading...</p>
                ) : currentSubscription ? (
                  <div>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <div>
                        <h4 className="mb-1">{currentSubscription.plan.name}</h4>
                        <p className="text-muted mb-1">
                          {currentSubscription.plan.description}
                        </p>
                        <p className="text-muted mb-0">
                          Expires on: {new Date(currentSubscription.endDate).toLocaleDateString()}
                        </p>
                      </div>
                      <span className="badge bg-success">Active</span>
                    </div>

                    {/* Display Limits */}
                    <div className="mt-4">
                      <h5>Limits</h5>
                      <ul className="list-unstyled">
                        <li>
                          <strong>Max Projects:</strong>{' '}
                          {currentSubscription.plan.maxProjects}
                        </li>
                        <li>
                          <strong>Max Users:</strong>{' '}
                          {currentSubscription.plan.maxUsers}
                        </li>
                        <li>
                          <strong>Max Tasks:</strong>{' '}
                          {currentSubscription.plan.maxTasks}
                        </li>
                      </ul>
                    </div>

                    {/* Display Offer Information if Applicable */}
                    {currentSubscription.isOverridden && (
                      <div className="mt-4">
                        <h5>Special Offer</h5>
                        <p className="text-success">
                          You are currently on a special offer with enhanced limits!
                        </p>
                      </div>
                    )}
                  </div>
                ) : (
                  <p>No active subscription.</p>
                )}
              </div>
            </div>

            {/* Subscription Plans List */}
            <div className="card bg-white rounded-lg shadow-sm">
              <div className="card-header bg-primary text-white">
                <h3 className="card-title">Subscription Plans</h3>
              </div>
              <div className="card-body">
                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <div className="row">
                    {subscriptionPlans.map((plan) => (
                      <div key={plan.id} className="col-md-4 mb-4">
                        <div className="card h-100 shadow-sm">
                          <div className="card-header bg-light">
                            <h5 className="card-title mb-0">{plan.name}</h5>
                          </div>
                          <div className="card-body">
                            <p className="card-text text-muted">{plan.description}</p>
                            <p className="card-text">
                              <strong>Price:</strong> ${plan.price} / {plan.duration} days
                            </p>
                            <ul className="list-unstyled">
                              <li>
                                <strong>Max Projects:</strong> {plan.maxProjects}
                              </li>
                              <li>
                                <strong>Max Users:</strong> {plan.maxUsers}
                              </li>
                              <li>
                                <strong>Max Tasks:</strong> {plan.maxTasks}
                              </li>
                            </ul>
                          </div>
                          <div className="card-footer bg-white">
                            <button
                              className="btn btn-primary btn-block"
                              onClick={() => handleActivatePlan(plan.id)}
                              disabled={currentSubscription?.plan.id === plan.id}
                            >
                              {currentSubscription?.plan.id === plan.id ? 'Active' : 'Activate'}
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </div>
  );
};

export default Subscription;