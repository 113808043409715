import React, { useState, useEffect } from 'react'; // Added useEffect
import { useNavigate } from 'react-router-dom';
import authService from '../../services/authService';

const Login = ({ setIsAuthenticated }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false); // State for Remember Me checkbox
  const [errors, setErrors] = useState({ email: '', password: '' });
  const navigate = useNavigate();

  // Pre-fill email from localStorage if "Remember Me" was checked previously
  useEffect(() => {
    const rememberedEmail = localStorage.getItem('rememberedEmail');
    if (rememberedEmail) {
      setEmail(rememberedEmail);
      setRememberMe(true); // Automatically check "Remember Me" if email is pre-filled
    }
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();

    // Reset errors
    setErrors({ email: '', password: '' });

    // Validate inputs
    let isValid = true;
    const newErrors = { email: '', password: '' };

    if (!email) {
      newErrors.email = 'Email is required';
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = 'Invalid email format';
      isValid = false;
    }

    if (!password) {
      newErrors.password = 'Password is required';
      isValid = false;
    }

    if (!isValid) {
      setErrors(newErrors);
      return;
    }

    setLoading(true);

    try {
      const response = await authService.login(email, password);

      if (response.data) {
        localStorage.setItem('authToken', response.data.access_token); // Store the token

        if (rememberMe) {
          // Store email in localStorage if Remember Me is checked
          localStorage.setItem('rememberedEmail', email);
        } else {
          // Remove email from localStorage if Remember Me is unchecked
          localStorage.removeItem('rememberedEmail');
        }

        setIsAuthenticated(true);
        navigate('/dashboard');
      }
    } catch (error) {
      console.error('Login failed', error);

      // Clear the password field on login failure
      setPassword('');
      if (error) {
        alert(error);
      } else {
        alert('An error occurred. Please try again later.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="hold-transition login-page">
      <div className="login-box">
        <div className="card card-outline card-primary">
          <div className="card-header text-center">
            <h1>
              <b>Login</b>
            </h1>
          </div>
          <div className="card-body">
            <p className="login-box-msg">Sign in to start your session</p>
            <form onSubmit={handleLogin}>
              {/* Email Field */}
              <div className="input-group mb-3">
                <input
                  type="email"
                  className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-envelope"></span> {/* Email Icon */}
                  </div>
                </div>
                {errors.email && <div className="invalid-feedback">{errors.email}</div>}
              </div>

              {/* Password Field */}
              <div className="input-group mb-3">
                <input
                  type="password"
                  className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-lock"></span> {/* Password Icon */}
                  </div>
                </div>
                {errors.password && <div className="invalid-feedback">{errors.password}</div>}
              </div>

              {/* Remember Me Checkbox */}
              <div className="row">
                <div className="col-8">
                  <div className="icheck-primary">
                    <input
                      type="checkbox"
                      id="remember"
                      checked={rememberMe}
                      onChange={(e) => setRememberMe(e.target.checked)}
                    />
                    <label htmlFor="remember" style={{ paddingLeft: '10px' }}>
                      Remember Me
                    </label>
                  </div>
                </div>
                <div className="col-4 text-right">
                  <a href="/forgot-password">Forgot Password?</a>
                </div>
              </div>

              {/* Login Button */}
              <div className="row">
                <div className="col-12">
                  <button type="submit" className="btn btn-primary btn-block" disabled={loading}>
                    {loading ? 'Logging in...' : 'Login'}
                  </button>
                </div>
              </div>

              {/* Register Link */}
              <div className="row">
                <div className="col-12 text-center mt-3">
                  <p>
                    Don't have an account? <a href="/register">Register here</a>
                  </p>
                </div>
              </div>

              {/* Terms and Conditions Link */}
              <div className="row">
                <div className="col-12 text-center mt-3">
                  <p>
                    By logging in, you agree to our{' '}
                    <a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer">
                      Terms and Conditions
                    </a>
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;