import React, { useState } from 'react';

const SearchBox = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (e) => {
    e.preventDefault();
    onSearch(searchTerm);
  };

  return (
    <form onSubmit={handleSearch} className="form-inline">
      <div className="input-group">
        <input
          type="text"
          className="form-control form-control-navbar"
          placeholder="Search projects..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ paddingRight: '40px' }} // Add padding to prevent text overlap with the icon
        />
        <div className="input-group-append">
          <button type="submit" className="btn btn-navbar" style={{ marginLeft: '-40px', zIndex: 1 }}>
            <i className="fas fa-search"></i>
          </button>
        </div>
      </div>
    </form>
  );
};

export default SearchBox;