import React, { useState, useEffect } from 'react';
import Sidebar from '../Common/Sidebar';
import Footer from '../Common/Footer';
import Header from '../Common/Header';
import projectService from '../../services/projectService';
import SearchBox from '../Common/SearchBox';
import ProjectsList from '../Common/ProjectsList';
import Pagination from '../Common/Pagination';
import NavbarButton from '../Common/NavbarButton';

const Projects = () => {
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem('isAuthenticated') === 'true'
  );
  const [projects, setProjects] = useState([]);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [search, setSearch] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isViewMode, setIsViewMode] = useState(false);
  const [currentProjectId, setCurrentProjectId] = useState(null);
  const [formData, setFormData] = useState({
    name: '', // Mandatory
    description: '', // Optional
    expectedHoursPerDay: null, // Optional
    offDays: [], // Optional
    category: '', // Optional
    hourlyCharge: null, // Optional
    expectedCompletionDate: '', // Optional
    expectedStartDate: '', // Optional
    status: '', // Optional
    startTime: '', // Optional
    endTime: '', // Optional
  });

  const toggleSidebar = () => {
    setSidebarCollapsed(!sidebarCollapsed);
  };

  const fetchProjectsData = async () => {
    try {
      const response = await projectService.fetchProjects(limit, offset, search);
      setProjects(response.data.projects);
      setTotal(response.data.total);
    } catch (error) {
      console.error('Failed to fetch projects', error);
    }
  };

  useEffect(() => {
    fetchProjectsData();
  }, [limit, offset, search]);

  const handleSearch = (searchTerm) => {
    setSearch(searchTerm);
    setOffset(0);
  };

  const handlePageChange = (newOffset) => {
    setOffset(newOffset);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsEditMode(false);
    setIsViewMode(false);
    setCurrentProjectId(null);
    // Reset form data when modal is closed
    setFormData({
      name: '',
      description: '',
      expectedHoursPerDay: null,
      offDays: [],
      category: '',
      hourlyCharge: null,
      expectedCompletionDate: '',
      expectedStartDate: '',
      status: '',
      startTime: '',
      endTime: '',
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    if (checked) {
      setFormData({
        ...formData,
        offDays: [...formData.offDays, name],
      });
    } else {
      setFormData({
        ...formData,
        offDays: formData.offDays.filter((day) => day !== name),
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Prepare the data to send
      const dataToSend = {
        name: formData.name, // Mandatory
        description: formData.description || undefined, // Optional
        expectedHoursPerDay: formData.expectedHoursPerDay || undefined, // Optional
        offDays: formData.offDays.length > 0 ? formData.offDays : [], // Optional
        category: formData.category || 'OTHER', // Optional
        hourlyCharge: formData.hourlyCharge || undefined, // Optional
        expectedCompletionDate: formData.expectedCompletionDate || undefined, // Optional
        expectedStartDate: formData.expectedStartDate || undefined, // Optional
        status: formData.status || undefined, // Optional
        startTime: formData.startTime || undefined, // Optional
        endTime: formData.endTime || undefined, // Optional
      };

      if (isEditMode && currentProjectId) {
        // Update existing project
        await projectService.updateProject(currentProjectId, dataToSend);
        console.log('Project updated successfully');
      } else {
        // Create new project
        await projectService.createProject(dataToSend);
        console.log('Project created successfully');
      }

      closeModal();
      fetchProjectsData(); // Refresh the projects list
    } catch (error) {
      console.error('Failed to save project:', error);
    }
  };

  const handleView = (project) => {
    setFormData({
      name: project.name,
      description: project.description || '',
      expectedHoursPerDay: project.expectedHoursPerDay || null,
      offDays: project.offDays || [],
      category: project.category || '',
      hourlyCharge: project.hourlyCharge || null,
      expectedCompletionDate: project.expectedCompletionDate || '',
      expectedStartDate: project.expectedStartDate || '',
      status: project.status || '',
      startTime: project.startTime || '',
      endTime: project.endTime || '',
    });
    setIsModalOpen(true);
    setIsViewMode(true); // Enable view mode
    setIsEditMode(false); // Disable edit mode
  };

  const handleEdit = (project) => {
    setFormData({
      name: project.name,
      description: project.description || '',
      expectedHoursPerDay: project.expectedHoursPerDay || null,
      offDays: project.offDays || [],
      category: project.category || '',
      hourlyCharge: project.hourlyCharge || null,
      expectedCompletionDate: project.expectedCompletionDate || '',
      expectedStartDate: project.expectedStartDate || '',
      status: project.status || '',
      startTime: project.startTime || '',
      endTime: project.endTime || '',
    });
    setIsModalOpen(true);
    setIsEditMode(true); // Enable edit mode
    setIsViewMode(false); // Disable view mode
    setCurrentProjectId(project.id);
  };

  const handleDelete = async (projectId) => {
    if (window.confirm('Are you sure you want to delete this project?')) {
      try {
        await projectService.deleteProject(projectId);
        console.log('Project deleted successfully');
        fetchProjectsData(); // Refresh the projects list
      } catch (error) {
        console.error('Failed to delete project:', error);
      }
    }
  };

  return (
    <div className={`wrapper ${sidebarCollapsed ? 'sidebar-collapse' : ''}`}>
      {/* Navbar */}
      <nav className="main-header navbar navbar-expand navbar-white navbar-light shadow-sm">
        <NavbarButton
          onClick={toggleSidebar}
          iconClass="fas fa-bars"
          buttonClass="custom-class"
          ariaLabel="Toggle navigation"
        />
        {/* Right Navbar Links */}
        <Header setIsAuthenticated={setIsAuthenticated} />
      </nav>

      <Sidebar sidebarCollapsed={sidebarCollapsed} />

      <div className="content-wrapper bg-gray-50">
        <section className="content py-6">
          <div className="container-fluid">
            {/* Main Card */}
            <div className="card bg-white rounded-lg shadow-sm">
              {/* Card Header */}
              <div className="card-header border-bottom bg-white">
                <div className="d-flex flex-column flex-md-row justify-content-between align-items-center gap-3">
                  <h3 className="card-title text-xl font-semibold text-gray-800 mb-0">Projects</h3>
                  <div className="w-100 w-md-auto">
                    <SearchBox onSearch={handleSearch} />
                  </div>
                </div>
              </div>

              {/* Card Body */}
              <div className="card-body p-0">
                {projects.length > 0 ? (
                  <ProjectsList
                    projects={projects}
                    onView={handleView}
                    onEdit={handleEdit}
                    onDelete={handleDelete}
                  />
                ) : (
                  <div className="text-center py-6">
                    <p className="text-gray-600">No projects found.</p>
                  </div>
                )}
              </div>

              {/* Card Footer - Pagination */}
              <div className="card-footer border-top bg-white">
                <Pagination
                  total={total}
                  limit={limit}
                  offset={offset}
                  onPageChange={handlePageChange}
                />
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* Floating Action Button (FAB) */}
      <button
        className="btn btn-primary btn-floating"
        onClick={openModal}
        style={{
          position: 'fixed',
          bottom: '80px', // Adjusted to push the FAB above the footer
          right: '20px',
          borderRadius: '50%',
          width: '60px',
          height: '60px',
          fontSize: '24px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        }}
      >
        <i className="fas fa-plus"></i>
      </button>

      {/* Modal */}
      {isModalOpen && (
        <div className="modal fade show" style={{ display: 'block' }}>
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  {isViewMode ? 'View Project' : isEditMode ? 'Update Project' : 'Create New Project'}
                </h5>
                <button type="button" className="close" onClick={closeModal}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <form onSubmit={handleSubmit}>
                  {/* Project Name (Mandatory) */}
                  <div className="form-group">
                    <label htmlFor="name">Project Name <span className="text-danger">*</span></label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                      required
                      disabled={isViewMode} // Disable in view mode
                    />
                  </div>

                  {/* Description (Optional) */}
                  <div className="form-group">
                    <label htmlFor="description">Description</label>
                    <textarea
                      className="form-control"
                      id="description"
                      name="description"
                      rows="3"
                      value={formData.description}
                      onChange={handleInputChange}
                      disabled={isViewMode} // Disable in view mode
                    ></textarea>
                  </div>

                  {/* Expected Hours Per Day (Optional) */}
                  <div className="form-group">
                    <label htmlFor="expectedHoursPerDay">Expected Hours Per Day</label>
                    <input
                      type="number"
                      className="form-control"
                      id="expectedHoursPerDay"
                      name="expectedHoursPerDay"
                      value={formData.expectedHoursPerDay || ''}
                      onChange={handleInputChange}
                      disabled={isViewMode} // Disable in view mode
                    />
                  </div>

                  {/* Off Days (Optional) */}
                  <div className="form-group">
                    <label>Off Days</label>
                    <div className="d-flex flex-wrap gap-3">
                      {['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'].map((day) => (
                        <div key={day} className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id={day}
                            name={day}
                            checked={formData.offDays.includes(day)}
                            onChange={handleCheckboxChange}
                            disabled={isViewMode} // Disable in view mode
                          />
                          <label className="form-check-label" htmlFor={day}>
                            {day}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>

                  {/* Category (Optional) */}
                  <div className="form-group">
                    <label htmlFor="category">Category</label>
                    <select
                      className="form-control"
                      id="category"
                      name="category"
                      value={formData.category}
                      onChange={handleInputChange}
                      disabled={isViewMode} // Disable in view mode
                    >
                      <option value="">Select Category</option>
                      <option value="DEVELOPMENT">Development</option>
                      <option value="DESIGN">Design</option>
                      <option value="MARKETING">Marketing</option>
                      <option value="SUPPORT">Support</option>
                    </select>
                  </div>

                  {/* Hourly Charge (Optional) */}
                  <div className="form-group">
                    <label htmlFor="hourlyCharge">Hourly Charge ($)</label>
                    <input
                      type="number"
                      className="form-control"
                      id="hourlyCharge"
                      name="hourlyCharge"
                      value={formData.hourlyCharge || ''}
                      onChange={handleInputChange}
                      disabled={isViewMode} // Disable in view mode
                    />
                  </div>

                  {/* Expected Start Date (Optional) */}
                  <div className="form-group">
                    <label htmlFor="expectedStartDate">Expected Start Date</label>
                    <input
                      type="date"
                      className="form-control"
                      id="expectedStartDate"
                      name="expectedStartDate"
                      value={formData.expectedStartDate}
                      onChange={handleInputChange}
                      disabled={isViewMode} // Disable in view mode
                    />
                  </div>

                  {/* Expected Completion Date (Optional) */}
                  <div className="form-group">
                    <label htmlFor="expectedCompletionDate">Expected Completion Date</label>
                    <input
                      type="date"
                      className="form-control"
                      id="expectedCompletionDate"
                      name="expectedCompletionDate"
                      value={formData.expectedCompletionDate}
                      onChange={handleInputChange}
                      disabled={isViewMode} // Disable in view mode
                    />
                  </div>

                  {/* Status (Optional) */}
                  <div className="form-group">
                    <label htmlFor="status">Status</label>
                    <select
                      className="form-control"
                      id="status"
                      name="status"
                      value={formData.status}
                      onChange={handleInputChange}
                      disabled={isViewMode} // Disable in view mode
                    >
                      <option value="">Select Status</option>
                      <option value="ACTIVE">Active</option>
                      <option value="INACTIVE">Inactive</option>
                      <option value="ON_HOLD">On Hold</option>
                    </select>
                  </div>

                  {/* Start Time (Optional) */}
                  <div className="form-group">
                    <label htmlFor="startTime">Start Time</label>
                    <input
                      type="time"
                      className="form-control"
                      id="startTime"
                      name="startTime"
                      value={formData.startTime}
                      onChange={handleInputChange}
                      disabled={isViewMode} // Disable in view mode
                    />
                  </div>

                  {/* End Time (Optional) */}
                  <div className="form-group">
                    <label htmlFor="endTime">End Time</label>
                    <input
                      type="time"
                      className="form-control"
                      id="endTime"
                      name="endTime"
                      value={formData.endTime}
                      onChange={handleInputChange}
                      disabled={isViewMode} // Disable in view mode
                    />
                  </div>

                  {/* Submit Button (Hidden in View Mode) */}
                  {!isViewMode && (
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" onClick={closeModal}>
                        Close
                      </button>
                      <button type="submit" className="btn btn-primary">
                        {isEditMode ? 'Update Project' : 'Save Project'}
                      </button>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal Backdrop */}
      {isModalOpen && <div className="modal-backdrop fade show"></div>}

      <Footer />
    </div>
  );
};

export default Projects;