import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

const Header = ({ setIsAuthenticated }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Clear authentication state
    setIsAuthenticated(false);

    // Remove stored data (e.g., from localStorage)
    localStorage.removeItem('isAuthenticated');

    // Redirect to the login page
    navigate('/login');
  };

  return (
    <ul className="navbar-nav ml-auto">
      <li className="nav-item">
        <Link to="/profile" className="nav-link">
          <i className="fas fa-user"></i> Profile
        </Link>
      </li>
      <li className="nav-item">
        <button className="nav-link" onClick={handleLogout} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
          <i className="fas fa-sign-out-alt"></i> Logout
        </button>
      </li>
    </ul>
  );
};

export default Header;