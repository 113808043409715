import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import authService from '../../services/authService';

const Register = () => {
  const [formData, setFormData] = useState({
    name: '',
    username: '',
    password: '',
    confirmPassword: '',
  });
  const [errors, setErrors] = useState({
    name: '',
    username: '',
    password: '',
    confirmPassword: '',
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };
  const validateForm = () => {
    const newErrors = {
      name: '',
      username: '',
      password: '',
      confirmPassword: '',
    };
  
    let isValid = true;
  
    // Name validation
    if (!formData.name) {
      newErrors.name = 'Name is required';
      isValid = false;
    } else if (/[^a-zA-Z\s]/.test(formData.name)) {
      newErrors.name = 'Name should not contain special characters';
      isValid = false;
    }
  
    // Email validation
    if (!formData.username) {
      newErrors.username = 'Email is required';
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.username)) {
      newErrors.username = 'Invalid email format';
      isValid = false;
    }
  
    // Password validation
    if (!formData.password) {
      newErrors.password = 'Password is required';
      isValid = false;
    } else if (formData.password.length < 9) {
      newErrors.password = 'Password must be at least 8 characters long';
      isValid = false;
    } else if (!/\d/.test(formData.password)) {
      newErrors.password = 'Password must contain at least 1 number';
      isValid = false;
    } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(formData.password)) {
      newErrors.password = 'Password must contain at least 1 special character';
      isValid = false;
    }
  
    // Confirm Password validation
    if (!formData.confirmPassword) {
      newErrors.confirmPassword = 'Confirm Password is required';
      isValid = false;
    } else if (formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = 'Passwords do not match';
      isValid = false;
    }
  
    setErrors(newErrors);
    return isValid;
  };
  const handleRegister = async (e) => {
    e.preventDefault();
  
    if (!validateForm()) {
      return; // Stop if validation fails
    }
  
    setLoading(true);
  
    try {
      const response = await authService.register({
        username: formData.username,
        password: formData.password,
        name: formData.name,
      });
  
      if (response.data) {
        alert('Registration successful! \nPlease verify your email to login.');
        navigate('/login');
      }
    } catch (error) {
  
      if (error) {  
        alert(error);
      } else {
        alert('An error occurred. Please try again later.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="hold-transition register-page">
      <div className="register-box">
        <div className="card card-outline card-primary">
          <div className="card-header text-center">
            <h1>
              <b>Register</b>
            </h1>
          </div>
          <div className="card-body">
            <p className="login-box-msg">Create a new account</p>
            <form onSubmit={handleRegister}>
              {/* Full Name Field */}
              <div className="input-group mb-3">
                <input
                  type="text"
                  className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                  placeholder="Full Name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-user"></span>
                  </div>
                </div>
                {errors.name && <div className="invalid-feedback">{errors.name}</div>}
              </div>
  
              {/* Email Field */}
              <div className="input-group mb-3">
                <input
                  type="email"
                  className={`form-control ${errors.username ? 'is-invalid' : ''}`}
                  placeholder="Email"
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-envelope"></span>
                  </div>
                </div>
                {errors.username && <div className="invalid-feedback">{errors.username}</div>}
              </div>
  
              {/* Password Field */}
              <div className="input-group mb-3">
                <input
                  type="password"
                  className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                  placeholder="Password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-lock"></span>
                  </div>
                </div>
                {errors.password && <div className="invalid-feedback">{errors.password}</div>}
              </div>
  
              {/* Confirm Password Field */}
              <div className="input-group mb-3">
                <input
                  type="password"
                  className={`form-control ${errors.confirmPassword ? 'is-invalid' : ''}`}
                  placeholder="Confirm Password"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-lock"></span>
                  </div>
                </div>
                {errors.confirmPassword && (
                  <div className="invalid-feedback">{errors.confirmPassword}</div>
                )}
              </div>
  
              {/* Register Button */}
              <div className="row">
                <div className="col-12">
                  <button type="submit" className="btn btn-primary btn-block" disabled={loading}>
                    {loading ? 'Creating Account...' : 'Register'}
                  </button>
                </div>
              </div>
  
              {/* Login Link */}
              <div className="row">
                <div className="col-12 text-center mt-3">
                  <p>
                    Already have an account? <a href="/login">Login here</a>
                  </p>
                </div>
              </div>
  
              {/* Terms and Conditions */}
              <div className="row">
                <div className="col-12 text-center mt-2">
                  <p>
                    By registering, you agree to our{' '}
                    <a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer">
                      Terms and Conditions
                    </a>
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;