// src/services/authService.js
import axios from 'axios';
import config from '../config/config';
import handleResponse from './handleResponse';

const login = async (username, password) => {
  const loginUrl = `${config.API_BASE_URL}${config.LOGIN_URL}`;
  const loginData = { username, password };

  try {
    const response = await axios.post(loginUrl, loginData);
    return response.data; // Return the response data (e.g., token)
  } catch (error) {
    console.log(error);
    throw new Error(error.response?.data?.errormessage[0] || 'Failed to login');
  }
};
const register = async (userData) => {
  
  try {
    const registerURL = `${config.API_BASE_URL}${config.REGISTER_URL}`;

    const response = await axios.post(registerURL, userData);  
    
    return response.data;
  }
  catch (error) {
    
    throw new Error(error.response?.data?.errormessage[0] || 'Failed to register');
  }

};
const verifyEmail = async (token) => {

  const verifyURL = `${config.API_BASE_URL}${config.VERIFY_EMAIL_URL}?token=${token}`;
  try {
    const response = await axios.get(verifyURL);  
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.errormessage[0] || 'Failed to verify email');
  }
};
const forgotUserPassword = async (userData) => {
  
  try {
    const forgotPasswordUrl = `${config.API_BASE_URL}${config.FORGOT_PASSWORD_URL}`;

    const response = await axios.post(forgotPasswordUrl, userData);  
    
    return response.data;
  }
  catch (error) {
    
    throw new Error(error.response?.data?.errormessage[0] || 'Failed to register');
  }

};
const resetPassword = async (userData) => {
  
  try {
    const resetPasswordUrl = `${config.API_BASE_URL}${config.RESET_PASSWORD_URL}`;

    const response = await axios.post(resetPasswordUrl, userData);  
    
    return response.data;
  }
  catch (error) {
    
    throw new Error(error.response?.data?.errormessage[0] || 'Failed to register');
  }

};
export default { login,register,verifyEmail,forgotUserPassword,resetPassword }; 