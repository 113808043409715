import axios from 'axios';
import config from '../config/config';

const fetchDashboardData = async () => {
  const dashboardDataUrl = `${config.API_BASE_URL}/projects/report`;

  try {
    const token = localStorage.getItem('authToken');
    const response = await axios.get(dashboardDataUrl, { headers: {
          Authorization: `Bearer ${token}`, // Add the Authorization header
        }, });
    console.log(response);
    return response.data; // Return the response data
  } catch (error) {
    console.error('Failed to fetch projects', error);
    throw error; // Re-throw the error for handling in the component
  }
};

export default { fetchDashboardData };