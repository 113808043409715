import React from "react";

const Footer = () => {
  return (
    <footer className="main-footer">
      <div className="float-right d-none d-sm-block">
        <b>Version</b> 1.0.0
      </div>
      <strong>
        © 2025 <a href="https://appitiza.net">Task Note</a>.
      </strong> All rights reserved.
    </footer>
  );
};

export default Footer;
