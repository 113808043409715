import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from '../Common/Sidebar'; 
import Footer from '../Common/Footer';  
import Header from '../Common/Header'

const Reminder = () => {
    const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(
        localStorage.getItem('isAuthenticated') === 'true'
      );

    const toggleSidebar = () => {
      setSidebarCollapsed(!sidebarCollapsed);
    };
  
    return (
      <div className={`wrapper ${sidebarCollapsed ? 'sidebar-collapse' : ''}`}>
        {/* Navbar */}
        <nav className="main-header navbar navbar-expand navbar-white navbar-light">
          {/* Left Navbar Links */}
          <ul className="navbar-nav">
            <li className="nav-item">
              <button
                className="nav-link"
                onClick={toggleSidebar}
                data-widget="pushmenu"
                role="button"
              >
                <i className="fas fa-bars"></i>
              </button>
            </li>
          </ul>
  
          {/* Right Navbar Links */}
        <Header setIsAuthenticated={setIsAuthenticated} />
        </nav>
  
        {/* Sidebar */}
        <Sidebar sidebarCollapsed={sidebarCollapsed} /> {/* Use the Sidebar component */}
  
        {/* Content Wrapper */}
        <div className="content-wrapper">
          {/* Main Content */}
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title">Welcome to the Dashboard</h3>
                    </div>
                    <div className="card-body">
                      <p>This is a modern and clean dashboard built with AdminLTE.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
  
        {/* Footer */}
        <Footer /> 
      </div>
    );
};

export default Reminder;