import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import authService from '../../services/authService';

const VerifyEmailPage = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const navigate = useNavigate();
  const [message, setMessage] = useState('Verifying your email...');

  const verifyEmail = async () => {
    try {
      const response = await authService.verifyEmail(token);

      if (response) {
        setMessage('Email verified successfully!');
        setTimeout(() => {
          navigate('/login'); // Redirect to login page after verification
        }, 3000);
      }
    } catch (error) {
      console.log(error);

      // Handle specific error messages
      if (error.message === 'Email is already verified') {
        setMessage('Your email is already verified. You can now log in.');
      } else {
        setMessage('Invalid or expired token. Please request a new verification email.');
      }
    }
  };

  useEffect(() => {
    if (token) {
      verifyEmail();
    } else {
      setMessage('Invalid verification link. Please check your email.');
    }
  }, [token, navigate]);

  return (
    <div style={{ padding: '20px' }}> {/* Add padding here */}
      <h1>Email Verification</h1>
      <p>{message}</p>
      {message.includes('Invalid') && !message.includes('already verified') && (
        <button onClick={() => navigate('/resend-verification')}>
          Resend Verification Email
        </button>
      )}
      {message.includes('already verified') && (
        <button onClick={() => navigate('/login')}>
          Go to Login
        </button>
      )}
    </div>
  );
};

export default VerifyEmailPage;