import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from '../Common/Sidebar';
import Footer from '../Common/Footer';
import Header from '../Common/Header';
import taskService from '../../services/taskService';
import SearchBox from '../Common/SearchBox';
import TasksList from '../Common/TasksList';
import Pagination from '../Common/Pagination';
import NavbarButton from '../Common/NavbarButton';

const Tasks = () => {
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem('isAuthenticated') === 'true'
  );
  const [tasks, setTasks] = useState([]);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [search, setSearch] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarCollapsed(!sidebarCollapsed);
  };

  const fetchTasksData = async () => {
    try {
      const response = await taskService.fetchTasks(limit, offset, search);
      setTasks(response.data.tasks);
      setTotal(response.data.total);
    } catch (error) {
      console.error('Failed to fetch tasks', error);
    }
  };

  useEffect(() => {
    fetchTasksData();
  }, [limit, offset, search]);

  const handleSearch = (searchTerm) => {
    setSearch(searchTerm);
    setOffset(0);
  };

  const handlePageChange = (newOffset) => {
    setOffset(newOffset);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className={`wrapper ${sidebarCollapsed ? 'sidebar-collapse' : ''}`}>
      {/* Navbar */}
      <nav className="main-header navbar navbar-expand navbar-white navbar-light shadow-sm">
        <NavbarButton
          onClick={toggleSidebar}
          iconClass="fas fa-bars"
          buttonClass="custom-class"
          ariaLabel="Toggle navigation"
        />
        {/* Right Navbar Links */}
        <Header setIsAuthenticated={setIsAuthenticated} />
      </nav>

      <Sidebar sidebarCollapsed={sidebarCollapsed} />

      <div className="content-wrapper bg-gray-50">
        <section className="content py-6">
          <div className="container-fluid">
            {/* Main Card */}
            <div className="card bg-white rounded-lg shadow-sm">
              {/* Card Header */}
              <div className="card-header border-bottom bg-white">
                <div className="d-flex flex-column flex-md-row justify-content-between align-items-center gap-3">
                  <h3 className="card-title text-xl font-semibold text-gray-800 mb-0">Tasks</h3>
                  <div className="w-100 w-md-auto">
                    <SearchBox onSearch={handleSearch} />
                  </div>
                </div>
              </div>

              {/* Card Body */}
              <div className="card-body p-0">
                {tasks.length > 0 ? (
                  <TasksList tasks={tasks} />
                ) : (
                  <div className="text-center py-6">
                    <p className="text-gray-600">No tasks found.</p>
                  </div>
                )}
              </div>

              {/* Card Footer - Pagination */}
              <div className="card-footer border-top bg-white">
                <Pagination
                  total={total}
                  limit={limit}
                  offset={offset}
                  onPageChange={handlePageChange}
                />
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* Floating Action Button (FAB) */}
      <button
        className="btn btn-primary btn-floating"
        onClick={openModal}
        style={{
          position: 'fixed',
          bottom: '80px', // Adjusted to push the FAB above the footer
          right: '20px',
          borderRadius: '50%',
          width: '60px',
          height: '60px',
          fontSize: '24px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        }}
      >
        <i className="fas fa-plus"></i>
      </button>

      {/* Modal */}
      {isModalOpen && (
        <div className="modal fade show" style={{ display: 'block' }}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Create New Task</h5>
                <button type="button" className="close" onClick={closeModal}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="form-group">
                    <label htmlFor="taskName">Task Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="taskName"
                      placeholder="Enter task name"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="taskDescription">Description</label>
                    <textarea
                      className="form-control"
                      id="taskDescription"
                      rows="3"
                      placeholder="Enter task description"
                    ></textarea>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={closeModal}>
                  Close
                </button>
                <button type="button" className="btn btn-primary">
                  Save Task
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal Backdrop */}
      {isModalOpen && <div className="modal-backdrop fade show"></div>}

      <Footer />
    </div>
  );
};

export default Tasks;