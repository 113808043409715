import axios from 'axios';
import config from '../config/config';

const fetchSubscriptionData = async (limit = 10, offset = 0, search = '') => {
  const url = `${config.API_BASE_URL}${config.SUBSCRIPTIONS}`;

  try {
    const token = localStorage.getItem('authToken');
    const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`, // Add the Authorization header
        }, });
    return response.data; // Return the response data
  } catch (error) {
    console.error('Failed to fetch subscription', error);
    throw error; // Re-throw the error for handling in the component
  }
};

export default { fetchSubscriptionData };