// src/config/config.js
const env = process.env.REACT_APP_ENV || 'local';

const local = {
  API_BASE_URL: 'http://localhost:3001', // Local API base URL
  LOGIN_URL: '/auth/login',              // Login endpoint
  REGISTER_URL: '/auth/register',        // Register endpoint
  VERIFY_EMAIL_URL: '/auth/verify-email', // Verify email endpoint
  RESEND_VERIFICATION_URL: '/auth/resend-verification', // Resend verification email endpoint
  PROJECTS: '/projects', // Projects endpoint
  ADD_PROJECT: '/projects', // Add project endpoint
  SUBSCRIPTIONS: '/subscriptions', // Subscriptions endpoint
  TASKS: '/tasks', // Tasks endpoint
  REMINDERS: '/reminders', // Reminders endpoint
  PROFILE: '/profile', // Profile endpoint
  RESET_PASSWORD_URL: '/auth/reset-password', // Reset password endpoint
  CHANGE_PASSWORD_URL: '/auth/change-password', // Change password endpoint
  FORGOT_PASSWORD_URL: '/auth/forgot-password', // Forgot password endpoint
  LOGOUT_URL: '/auth/logout', // Logout endpoint
};

const production = {
  API_BASE_URL: 'https://task.appitiza.net/api',
  LOGIN_URL: '/auth/login',              // Login endpoint
  REGISTER_URL: '/auth/register',        // Register endpoint
  VERIFY_EMAIL_URL: '/auth/verify-email', // Verify email endpoint
  RESEND_VERIFICATION_URL: '/auth/resend-verification', // Resend verification email endpoint
  PROJECTS: '/projects', // Projects endpoint
  ADD_PROJECT: '/projects', // Add project endpoint
  SUBSCRIPTIONS: '/subscriptions', // Subscriptions endpoint
  TASKS: '/tasks', // Tasks endpoint
  REMINDERS: '/reminders', // Reminders endpoint
  PROFILE: '/profile', // Profile endpoint
  RESET_PASSWORD_URL: '/auth/reset-password', // Reset password endpoint
  CHANGE_PASSWORD_URL: '/auth/change-password', // Change password endpoint
  FORGOT_PASSWORD_URL: '/auth/forgot-password', // Forgot password endpoint
  LOGOUT_URL: '/auth/logout', // Logout endpoint
};

const configs = {
  local,
  production,
};

const config = configs[env];

export default config;