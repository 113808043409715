import React, { useState, useEffect } from 'react';
import Sidebar from '../Common/Sidebar';
import Footer from '../Common/Footer';
import Header from '../Common/Header';
import NavbarButton from '../Common/NavbarButton';
import { Bar, Pie } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import 'admin-lte/dist/css/adminlte.min.css';
import dashboardService from '../../services/dashboardService';

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement);

const Dashboard = () => {
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const isBrowser = typeof window !== 'undefined'; // Check if running in a browser
  const [isAuthenticated, setIsAuthenticated] = useState(
    isBrowser ? localStorage.getItem('isAuthenticated') === 'true' : false
  );
  const [loading, setLoading] = useState(true);
  const [dashboardData, setDashboardData] = useState({
    summary: {
      tasksByStatus: { IN_PROGRESS: 0, PAUSED: 0, COMPLETED: 0 },
      projectsByStatus: { ACTIVE: 0, ON_HOLD: 0, INACTIVE: 0, COMPLETED: 0 },
      totalTasks: 0,
      totalProjects: 0,
      totalOverdueTasks: 0,
      totalOvertimeTasks: 0,
    },
    projects: [],
  });

  const toggleSidebar = () => {
    setSidebarCollapsed(!sidebarCollapsed);
  };

  const fetchDashboardData = async () => {
    try {
      const response = await dashboardService.fetchDashboardData();
      console.log('API Response:', response); // Log the API response
      setDashboardData({
        summary: response.data.summary || {
          tasksByStatus: { IN_PROGRESS: 0, PAUSED: 0, COMPLETED: 0 },
          projectsByStatus: { ACTIVE: 0, ON_HOLD: 0, INACTIVE: 0, COMPLETED: 0 },
          totalTasks: 0,
          totalProjects: 0,
          totalOverdueTasks: 0,
          totalOvertimeTasks: 0,
        },
        projects: response.data.projects || [],
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch data on component mount
  useEffect(() => {
    fetchDashboardData();
  }, []);

  // Log dashboardData state
  useEffect(() => {
    console.log('Dashboard Data:', dashboardData); // Log the dashboardData state
  }, [dashboardData]);

  // Chart data for tasks by status
  const tasksByStatusData = {
    labels: ['In Progress', 'Paused', 'Completed'],
    datasets: [
      {
        label: 'Tasks',
        data: [
          dashboardData.summary.tasksByStatus.IN_PROGRESS || 0,
          dashboardData.summary.tasksByStatus.PAUSED || 0,
          dashboardData.summary.tasksByStatus.COMPLETED || 0,
        ],
        backgroundColor: ['#36A2EB', '#FFCE56', '#4BC0C0'],
      },
    ],
  };

  // Chart data for projects by status
  const projectsByStatusData = {
    labels: ['Active', 'On Hold', 'Inactive', 'Completed'],
    datasets: [
      {
        label: 'Projects',
        data: [
          dashboardData.summary.projectsByStatus.ACTIVE || 0,
          dashboardData.summary.projectsByStatus.ON_HOLD || 0,
          dashboardData.summary.projectsByStatus.INACTIVE || 0,
          dashboardData.summary.projectsByStatus.COMPLETED || 0,
        ],
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'],
      },
    ],
  };

  // Log chart data
  console.log('Tasks by Status Data:', tasksByStatusData);
  console.log('Projects by Status Data:', projectsByStatusData);

  return (
    <div className={`wrapper ${sidebarCollapsed ? 'sidebar-collapse' : ''}`}>
      {/* Navbar */}
      <nav className="main-header navbar navbar-expand navbar-white navbar-light">
        {/* Left Navbar Links */}
        <NavbarButton
          onClick={toggleSidebar}
          iconClass="fas fa-bars"
          buttonClass="custom-class"
          ariaLabel="Toggle navigation"
        />

        {/* Right Navbar Links */}
        <Header setIsAuthenticated={setIsAuthenticated} />
      </nav>

      {/* Sidebar */}
      <Sidebar sidebarCollapsed={sidebarCollapsed} />

      {/* Content Wrapper */}
      <div className="content-wrapper">
        {/* Main Content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">Welcome to the Dashboard</h3>
                  </div>
                  <div className="card-body">
                    {loading ? (
                      <div className="text-center">
                        <div className="spinner-border text-primary" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                        <p>Loading dashboard data...</p>
                      </div>
                    ) : (
                      <>
                        <div className="row">
                          {/* Tasks by Status Chart */}
                          <div className="col-md-6">
                            <div className="card">
                              <div className="card-header">
                                <h3 className="card-title">Tasks by Status</h3>
                              </div>
                              <div className="card-body chart-container">
                                <Pie data={tasksByStatusData} />
                              </div>
                            </div>
                          </div>

                          {/* Projects by Status Chart */}
                          <div className="col-md-6">
                            <div className="card">
                              <div className="card-header">
                                <h3 className="card-title">Projects by Status</h3>
                              </div>
                              <div className="card-body chart-container">
                                <Bar data={projectsByStatusData} />
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* Summary Cards */}
                        <div className="row mt-4">
                          <div className="col-md-3">
                            <div className="info-box">
                              <span className="info-box-icon bg-info">
                                <i className="fas fa-tasks"></i>
                              </span>
                              <div className="info-box-content">
                                <span className="info-box-text">Total Tasks</span>
                                <span className="info-box-number">
                                  {dashboardData.summary.totalTasks || 0}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="info-box">
                              <span className="info-box-icon bg-success">
                                <i className="fas fa-project-diagram"></i>
                              </span>
                              <div className="info-box-content">
                                <span className="info-box-text">Total Projects</span>
                                <span className="info-box-number">
                                  {dashboardData.summary.totalProjects || 0}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="info-box">
                              <span className="info-box-icon bg-warning">
                                <i className="fas fa-clock"></i>
                              </span>
                              <div className="info-box-content">
                                <span className="info-box-text">Overdue Tasks</span>
                                <span className="info-box-number">
                                  {dashboardData.summary.totalOverdueTasks || 0}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="info-box">
                              <span className="info-box-icon bg-danger">
                                <i className="fas fa-exclamation-triangle"></i>
                              </span>
                              <div className="info-box-content">
                                <span className="info-box-text">Overtime Tasks</span>
                                <span className="info-box-number">
                                  {dashboardData.summary.totalOvertimeTasks || 0}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default Dashboard;