import axios from 'axios';
import config from '../config/config';

const fetchProjects = async (limit = 10, offset = 0, search = '') => {
  const projectsUrl = `${config.API_BASE_URL}${config.PROJECTS}`;
  const params = { limit, offset, search };

  try {
    const token = localStorage.getItem('authToken');
    const response = await axios.get(projectsUrl, { params,
        headers: {
          Authorization: `Bearer ${token}`, 
          "Content-Type": "application/json", 
        }, });
    return response.data; // Return the response data
  } catch (error) {
    console.error('Failed to fetch projects', error);
    throw error; // Re-throw the error for handling in the component
  }
};
const createProject = async (data) => {
  const url = `${config.API_BASE_URL}${config.PROJECTS}`;

  try {
    const token = localStorage.getItem('authToken');
    const response = await axios.post(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json", 
      },
    });
    return response.data; // Return the response data
  } catch (error) {
    console.error('Failed to add projects', error);
    throw error; // Re-throw the error for handling in the component
  }
};
const updateProject = async (id, data) => {
  const response = await axios.put(`${config.API_BASE_URL}${config.PROJECTS}/${id}`, data);
  return response.data;
};

const deleteProject = async (id) => {
  const response = await axios.delete(`/api/projects/${id}`);
  return response.data;
};

export default { fetchProjects,createProject,updateProject,deleteProject };