import React from 'react';

const Pagination = ({ total, limit, offset, onPageChange }) => {
  const totalPages = Math.ceil(total / limit);
  const currentPage = Math.floor(offset / limit) + 1;

  const handlePageChange = (page) => {
    const newOffset = (page - 1) * limit;
    onPageChange(newOffset);
  };

  return (
    <nav>
      <ul className="pagination pagination-sm m-0 float-right">
        {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
          <li
            key={page}
            className={`page-item ${currentPage === page ? 'active' : ''}`}
          >
            <button className="page-link" onClick={() => handlePageChange(page)}>
              {page}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Pagination;